import logoimg from '../assets/logo.png';

const Footer = () => {
    return (
        <section className="relative bg-white mt-10">
            <footer className="bg-gray-50">
              <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center sm:justify-between">
                  <div className="flex justify-center text-teal-600 sm:justify-start h-[30px]">        
                    <img src={logoimg} alt="logoimg" />
                  </div>
                  <div className='hidden md:flex pr-4'>
        <div className='gap-4'>            
            <a className="flex text-center cursor-pointer items-center mx-4 text-black hover:text-blue-600">
              <span className="text-lg font-medium">
                +7 (495) 777-18-83
              </span>
            </a>
          <a className="flex text-center cursor-pointer items-center mx-4 text-black hover:text-blue-600">
              <span className="text-lg font-medium">sales@itckvazar.ru</span>
            </a> 
          </div>
        </div>
                  <p className="mt-4 text-center text-sm text-gray-500 lg:mt-0 lg:text-right">
                    &copy; 2024
                  </p>
                </div>
              </div>
            </footer>
          </section>
    );
}
export default Footer