// Catalog component 
import React, { useEffect } from "react";
import { Link } from 'react-router-dom';

import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";
import ProductTableDiskDv from "../components/ProductTableDiskDv";


import backgroundimg from '../assets/backgroundimg.webp';
import indimg from '../assets/ind.webp';

import doubvalve from '../assets/doubvalve.jpg';


const ZatvoryDiskovyeDv = () => {
  useEffect(() => {
    document.title = 'Затворы с двойным эксцентриситетом - ИТЦ Квазар';
  }, []);
    return(
      <div className="App">
        <Navigation />
          <div className="relative">
            {/* background image */}
            <div className="absolute inset-0 z-0"
              style={{
              backgroundImage: `url(${backgroundimg})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',}}
            ></div>

        {/* section starts here */}

          <section className="relative md:block hidden">
            <div className='w-full h-[200px] bg-gray-900/90 absolute'>
              <img className='w-full h-full object-cover mix-blend-overlay' src={indimg} alt="indimg" />
            </div>
            <div className='max-w-[1240px] mx-auto text-white relative'>
              <div className='px-4 py-12 md:block hidden'>
                <h4 className='text-3xl font-bold py-6 text-center'><Link to='/catalog'><span className="underline">Запорно-регулирующая арматура</span></Link> / <Link to='/catalog/zatvory-diskovye-povorotnye'><span className="underline">Затворы дисковые поворотныe</span></Link></h4>
              </div>
                <div className='w-full h-[50px] relative'>
              </div>
            </div>
          </section>
                 
          <section className="relative">
            <div className="container max-w-xl p-6 mx-auto space-y-12 lg:px-8 lg:max-w-7xl">
              <div>
                <h2 className="text-3xl font-bold text-left sm:text-5xl">Затворы с двойным эксцентриситетом</h2>
                <br />
                <br />
                <p className="mx-auto mt-4 text-xl text-left "> Дисковый поворотный межфланцевый двухэксцентриковый затвор предназначен для использования в технологических трубопроводных системах различных отраслей промышленности, включая газовую и газоперерабатывающую, нефтяную и нефтеперерабатывающую, химическую и нефтехимическую, 
                  а также металлургическую. Эти затворы обеспечивают регулировку и перекрытие потоков среды, а также контроль параметров рабочей среды.
                    <br />
                </p>
              </div>
              <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
                <div>
                  <div className="mt-4 space-y-12">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center w-12 h-12 rounded-md">
                          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                            <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                          </svg> 
                        </div>
                      </div> 
                      <p className="mt-2 ">Корпус и шток затворов изготавливаются из стали или чугуна, что обеспечивает прочность и надежность конструкции. 
                        Для уплотнений могут использоваться бронза и коррозионностойкая сталь, что способствует долговечности и устойчивости к агрессивным условиям эксплуатации.</p>
                  </div>
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 rounded-md">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                        </svg> 
                      </div>
                    </div>
                    <p className="mt-2">Управление затвором может осуществляться различными способами, включая ручное управление с помощью рукоятки, редуктора, а также автоматизированные системы с электроприводом или пневмоприводом.</p>
                  </div>
                  <div className="flex">
                  <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 rounded-md">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                        </svg> 
                      </div>
                    </div>
                    <p className="mt-2">Затворы данного типа могут работать с различными рабочими средами, такими как вода, пар, воздух, газообразные нефтепродукты, природный газ, газообразный аммиак и водогазонефтяные смеси. 
                      Они также способны обрабатывать агрессивные вещества, включая кислоты, щелочи, нефть, аммиак, нефтепродукты, углеводороды, нефтяной попутный газ и спирты, что делает их универсальными для применения в сложных промышленных условиях.</p>
                  </div>
      
                </div>
              </div>
              <div aria-hidden="true" className="mt-10 lg:mt-0">
                <img src={doubvalve} alt="doubvalve" className="mx-auto rounded-lg shadow-lg dark-bg-gray-500" />
              </div>
            </div>
            </div>
          </section>
          <ProductTableDiskDv />
          <section className="relative">
            <ContactForm />
          </section>
          <Footer />
      </div>
    </div>
  );
}
    

export default ZatvoryDiskovyeDv;