import rfafimg from '/home/prj/website/src/assets/rfaf.png';
import zagl3 from '/home/prj/website/src/assets/zagl3-3.jpg';
import ksh1 from '/home/prj/website/src/assets/ksh-1.jpg';
import fln1 from '/home/prj/website/src/assets/flancy-1.jpg';
import klp1 from '/home/prj/website/src/assets/klap-3.jpg';
import klin1 from '/home/prj/website/src/assets/klin-1.jpg';
import kip1 from '/home/prj/website/src/assets/kip-1.jpg';
import eldv1 from '/home/prj/website/src/assets/eldv-1.jpg';
import geo1 from '/home/prj/website/src/assets/geo-1.jpg';

import { Link } from 'react-router-dom';

const Grid = () => {
    return (
        <>
        <div class="grid place-items-center">
            <h1 class="text-2xl md:text-3xl p-2 my-2 border-l-4 border-b-4 font-sans font-bold border-blue-400  dark:text-gray-200">
                Запорно-регулирующая арматура:
            </h1>
        </div>
        <div class="grid place-items-center">
            <div className="w-4/5">
                <div className="grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-4 pb-4">
                    <Link to='/catalog/zatvory-diskovye-povorotnye'>
                    <div className="relative rounded overflow-hidden bg-gray-200 hover:bg-gray-100 hover:border-gray-100 text-center cursor-pointer">
                        <div className="relative flex h-[250px] group">
                            <img src={zagl3} alt="Hanging Planters" className="h-full w-full object-cover object-center transition-transform transform scale-100 group-hover:scale-105" />
                            <div className="absolute top-1/2 left-10 max-w-full md:left-20">
                                <div className="bg-gray-200 text-3xl font-bold text-black mix-blend-screen px-2 py-2 ">Затворы дисковые поворотные</div>
                            </div>
                        </div>
                    </div>
                    </Link>
                    <Link to='/catalog/gazovye-krany-sharovye'>
                    <div className="relative rounded overflow-hidden bg-gray-200 hover:bg-gray-100 hover:border-gray-100 text-center cursor-pointer">
                        <div className="relative flex h-[250px] group">
                            <img src={ksh1} alt="Hanging Planters" className="h-full w-full object-cover object-center transition-transform transform scale-100 group-hover:scale-105" />
                            <div className="absolute top-1/2 left-10 max-w-full md:left-20">
                                <div className="bg-gray-200 text-3xl font-bold text-black mix-blend-screen px-2 py-2 ">Краны шаровые для газа </div>
                            </div>
                        </div>
                    </div>
                    </Link>
                    <Link to='/catalog/flancy'>
                    <div className="relative rounded overflow-hidden bg-gray-200 hover:bg-gray-100 hover:border-gray-100 text-center cursor-pointer">
                        <div className="relative flex h-[250px] group">
                            <img src={fln1} alt="Hanging Planters" className="h-full w-full object-cover object-center transition-transform transform scale-100 group-hover:scale-105" />
                            <div className="absolute top-1/2 left-10 max-w-full md:left-20">
                                <div className="bg-gray-200 text-3xl font-bold text-black mix-blend-screen px-2 py-2 ">Фланцы</div>
                            </div>
                        </div>
                    </div>
                    </Link>
                </div>
                
                <div className="grid sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-4 pb-4">
                    <Link to='/catalog/klapany-zapornye'>
                    <div className="relative rounded overflow-hidden bg-gray-200 hover:bg-gray-100 hover:border-gray-100 text-center cursor-pointer">
                        <div className="relative flex h-[250px] group">
                            <img src={klp1} alt="Hanging Planters" className="h-full w-full object-cover object-center transition-transform transform scale-100 group-hover:scale-105" />
                            <div className="absolute top-1/2 left-10 max-w-full md:left-20">
                                <div className="bg-gray-200 text-3xl font-bold text-black mix-blend-screen px-2 py-2 ">Клапаны запорные</div>
                            </div>
                        </div>
                    </div>
                    </Link>
                    <Link to='/catalog/zadvizhki-klinovye'>
                    <div className="relative rounded overflow-hidden bg-gray-200 hover:bg-gray-100 hover:border-gray-100 text-center cursor-pointer">
                        <div className="relative flex h-[250px] group">
                            <img src={klin1} alt="Hanging Planters" className="h-full w-full object-cover object-center transition-transform transform scale-100 group-hover:scale-105" />
                            <div className="absolute top-1/2 left-10 max-w-full md:left-20">
                                <div className="bg-gray-200 text-3xl font-bold text-black mix-blend-screen px-2 py-2 ">Задвижки клиновые</div>
                            </div>
                        </div>
                    </div>
                    </Link>
                </div>
                <div class="grid place-items-center">
                    <h1 class="text-2xl md:text-3xl p-2 my-2 border-l-4 border-b-4 font-sans font-bold border-blue-400  dark:text-gray-200">
                        Электротехнические изделия параллельного импорта:
                    </h1>
                </div>
                <div className="grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-4 pb-4">
                    <Link to='/catalog/kipia'>
                    <div className="relative rounded overflow-hidden bg-gray-200 hover:bg-gray-100 hover:border-gray-100 text-center cursor-pointer">
                        <div className="relative flex h-[250px] group">
                            <img src={kip1} alt="Hanging Planters" className="h-full w-full object-cover object-center transition-transform transform scale-100 group-hover:scale-105" />
                            <div className="absolute top-1/2 left-5 max-w-full md:left-5">
                                <div className="bg-gray-200 text-3xl font-bold text-black mix-blend-screen px-2 py-2 ">Контрольно-измерительные приборы и автоматика</div>
                            </div>
                        </div>
                    </div>
                    </Link>
                    <Link to='/catalog/eldvig'>
                    <div className="relative rounded overflow-hidden bg-gray-200 hover:bg-gray-100 hover:border-gray-100 text-center cursor-pointer">
                        <div className="relative flex h-[250px] group">
                            <img src={eldv1} alt="Hanging Planters" className="h-full w-full object-cover object-center transition-transform transform scale-100 group-hover:scale-105" />
                            <div className="absolute top-1/2 left-5 max-w-full md:left-5">
                                <div className="bg-gray-200 text-3xl font-bold text-black mix-blend-screen px-2 py-2 ">Электродвигатели, частотно-регулируемые приводы и автоматика</div>
                            </div>
                        </div>
                    </div>
                    </Link>
                    <div className="relative rounded overflow-hidden bg-gray-200 hover:bg-gray-100 hover:border-gray-100 text-center cursor-pointer">
                        <div className="relative flex h-[250px] group">
                            <img src={geo1} alt="Hanging Planters" className="h-full w-full object-cover object-center transition-transform transform scale-100 group-hover:scale-105" />
                            <div className="absolute top-1/2 left-5 max-w-full md:left-5">
                                <div className="bg-gray-200 text-3xl font-bold text-black mix-blend-screen px-2 py-2 ">Автономные средства геолокации и геологоразведки</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>    
    );
}
export default Grid
