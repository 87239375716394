// Catalog component 
import React, { useEffect } from "react";
import { Link } from 'react-router-dom';

import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";
import ProductTableDiskSym from "../components/ProductTableDiskSym";

import backgroundimg from '../assets/backgroundimg.webp';
import indimg from '../assets/ind.webp';

import centrvalve from '/home/prj/website/src/assets/centrvalve.jpg';



const ZatvoryDiskovyeSym = () => {
  useEffect(() => {
    document.title = 'Затворы дисковые с симметричным диском - ИТЦ Квазар';
  }, []);
    return(
      <div className="App">
        <Navigation />
          <div className="relative">
            {/* background image */}
            <div className="absolute inset-0 z-0"
              style={{
              backgroundImage: `url(${backgroundimg})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',}}
            ></div>

        {/* section starts here */}

          <section className="relative md:block hidden">
            <div className='w-full h-[200px] bg-gray-900/90 absolute'>
              <img className='w-full h-full object-cover mix-blend-overlay' src={indimg} alt="indimg" />
            </div>
            <div className='max-w-[1240px] mx-auto text-white relative'>
              <div className='px-4 py-12 md:block hidden'>
                <h4 className='text-3xl font-bold py-6 text-center'><Link to='/catalog'><span className="underline">Запорно-регулирующая арматура</span></Link> / <Link to='/catalog/zatvory-diskovye-povorotnye'><span className="underline">Затворы дисковые поворотныe</span></Link></h4>
              </div>
                <div className='w-full h-[50px] relative'>
              </div>
            </div>
          </section>
                 
          <section className="relative">
            <div className="container max-w-xl p-6 mx-auto space-y-12 lg:px-8 lg:max-w-7xl">
              <div>
                <h2 className="text-3xl font-bold text-left sm:text-5xl">Затворы дисковые с симметричным диском</h2>
                <br />
                <br />
                <p className="mx-auto mt-4 text-xl text-left "> Дисковый поворотный межфланцевый затвор с симметричным диском разработан для эксплуатации в условиях с высокими температурами, частыми циклами срабатывания и наличием агрессивных сред. 
                  Эти затворы характеризуются симметрично расположенным диском и оснащены эластомерными седлами, которые обеспечивают мягкое уплотнение и надежную герметичность.
                    <br />
                </p>
              </div>
              <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
                <div>
                  <div className="mt-4 space-y-12">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center w-12 h-12 rounded-md">
                          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                            <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                          </svg> 
                        </div>
                      </div> 
                
                        {/*<h4 className="text-lg font-medium leadi ">Основные преимущества дисковых затворов заключаются в их простой конструкции и низкой стоимости.
                        Обычно затвор состоит из небольшого количества деталей, что облегчает процесс ремонта и замену вышедших из строя компонентов. В затворе всего четыре основных элемента: корпус, диск, поворотный вал (шпиндель) и седло.
                        </h4> */}
                    <p className="mt-2 ">Управление затвором может осуществляться вручную с помощью рукоятки или с использованием электропривода или пневмопривода, что позволяет адаптировать его к различным условиям эксплуатации.</p>
                  </div>
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 rounded-md">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                        </svg> 
                      </div>
                    </div>
                    <p className="mt-2">Поворотный вал (или шпиндель) дискового затвора играет ключевую роль в его работе и может быть выполнен как в цельном исполнении, так и в виде двух частей.</p>
                  </div>
                  <div className="flex">
                  <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 rounded-md">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                        </svg> 
                      </div>
                    </div>
                    <p className="mt-2">В качестве материалов для изготовления затвора используются углеродистая или нержавеющая сталь, иногда с добавлением молибдена для повышения коррозионной стойкости. 
                      Шток затвора изготавливается из легированной или нержавеющей стали, а седла могут быть выполнены из силикона, ЭПДМ, нитрила или гипалона, что обеспечивает долговечность и устойчивость к агрессивным химическим веществам.</p>
                  </div>
      
                </div>
              </div>
              <div aria-hidden="true" className="mt-10 lg:mt-0">
                <img src={centrvalve} alt="centrvalve" className="mx-auto rounded-lg shadow-lg dark-bg-gray-500" />
              </div>
            </div>
            </div>
          </section>
          <ProductTableDiskSym />
          <section className="relative">
            <ContactForm />
          </section>
          <Footer />
      </div>
    </div>
  );
}
    

export default ZatvoryDiskovyeSym;