// Catalog component 
import React, { useEffect } from "react";
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

import Navigation from "../components/Navigation";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";

import backgroundimg from '../assets/backgroundimg.webp';
import indimg from '../assets/ind.webp';



const Kontakty = () => {
  useEffect(() => {
    document.title = 'Контактная информация - ИТЦ Квазар';
  }, []);
    return(
      <div className="App">
        <Navigation />
          <div className="relative">
            {/* background image */}
            <div className="absolute inset-0 z-0"
              style={{
              backgroundImage: `url(${backgroundimg})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',}}
            ></div>

          <section className="relative md:block hidden">
            <div className='w-full h-[200px] bg-gray-900/90 absolute'>
              <img className='w-full h-full object-cover mix-blend-overlay' src={indimg} alt="indimg" />
            </div>
            <div className='max-w-[1240px] mx-auto text-white relative'>
              <div className='px-4 py-12 md:block hidden'>
                <h3 className='text-5xl font-bold py-6 text-center'>Контактная информация</h3>
              </div>
                <div className='w-full h-[50px] relative'>
              </div>
            </div>
          </section>
            
          <section className="relative">
          <div className="relative pb-12 flex items-top justify-center dark:bg-gray-900 sm:items-center sm:pt-0">
          <div className="max-w-6xl mx-auto sm:px-6 lg:px-8">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ ИНЖЕНЕРНО-ТЕХНИЧЕСКИЙ ЦЕНТР "КВАЗАР"</h2>
           { /* <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Our Store
            </p>
            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus magnam voluptatum cupiditate veritatis
                in, accusamus quisquam.
            </p> */ }
        </div>

        <div className="mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                            
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
                            </svg>

                        </div>
                    </div>
                    <div className="ml-4">
                        <dt className="text-lg leading-6 font-medium text-gray-900">
                            Адрес
                        </dt>
                        <dd className="mt-2 text-base text-gray-500">
                          Московская область, с Булатниково, тер. ТВХ Каштан, стр. 215 
                        </dd>
                    </div>
                </div>

                <div className="flex">
                    <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                           
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                            </svg>

                        </div>
                    </div>
                    <div className="ml-4">
                        <dt className="text-lg leading-6 font-medium text-gray-900">
                            Номер телефона
                        </dt>
                        <dd className="mt-2 text-base text-gray-500">
                          +7 (495) 777-18-83
                        </dd>
                    </div>
                </div>

                <div className="flex">
                    <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                            
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                            </svg>

                        </div>
                    </div>
                    <div className="ml-4">
                        <dt className="text-lg leading-6 font-medium text-gray-900">
                            Эл. почта
                        </dt>
                        <dd className="mt-2 text-base text-gray-500">
                            sales@itckvazar.ru
                        </dd>
                    </div>
                </div>

                <div className="flex">
                    <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>

                        </div>
                    </div>
                    <div className="ml-4">
                        <dt className="text-lg leading-6 font-medium text-gray-900">
                            Время работы
                        </dt>
                        <dd className="mt-2 text-base text-gray-500">
                            Пн-Пт: с 9:00 до 20:00<br />
                            Сб-Вс: выходные
                        </dd>
                    </div>
                </div>
            </dl>
        </div>
    </div>
</div>

</div>

          </section>
          <section className="relative flex items-center justify-center">
            <div className="container max-w-xl lg:max-w-7xl">
            <div>
            <YMaps>
                <Map
                    width={'100%'}
                    height={'300px'}                    
                    defaultState={{
                    center: [55.56231986410173, 37.6616218237632],
                    zoom: 10,
                    }}
                >
                <Placemark defaultGeometry={[55.56206752936122, 37.66081716105873]} />
                </Map>
            </YMaps>
        </div>
        </div>
        </section>
          <section className="relative">
            <ContactForm />
          </section>
          <Footer />
      </div>
    </div>
  );
}
    

export default Kontakty;