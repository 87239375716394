// Catalog component 
import React, { useEffect } from "react";
import { Link } from 'react-router-dom';

import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";

import backgroundimg from '../assets/backgroundimg.webp';
import indimg from '../assets/ind.webp';


const Kipia = () => {
    useEffect(() => {
        document.title = 'КИПиА - ИТЦ Квазар';
      }, []);
  return(
    <div className="App">
      <Navigation />
        <div className="relative">
          {/* background image */}
          <div className="absolute inset-0 z-0"
            style={{
            backgroundImage: `url(${backgroundimg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',}}
          ></div>

      {/* section starts here */}

        <section className="relative md:block hidden">
          <div className='w-full h-[200px] bg-gray-900/90 absolute'>
            <img className='w-full h-full object-cover mix-blend-overlay' src={indimg} alt="indimg" />
          </div>
          <div className='max-w-[1240px] mx-auto text-white relative'>
            <div className='px-4 py-12 md:block hidden'>
              <h3 className='text-5xl font-bold py-6 text-center'><Link to='/catalog'><span className="underline">Электротехнические изделия <br />параллельного импорта</span></Link></h3>
            </div>
              <div className='w-full h-[50px] relative'>
            </div>
          </div>
        </section>
      
        <section className="relative">
          <div className="container max-w-xl p-6 mx-auto space-y-12 lg:px-8 lg:max-w-7xl">
            
            <h2 className="text-3xl font-bold text-left sm:text-5xl">КИПиА</h2>
              <p className="mx-auto mt-4 text-xl text-left ">
                  Наша компания предоставляет услуги по поиску, подбору и осуществлению поставок оригинального электротехнического оборудования и его аналогов в РФ. Мы специализируемся не только на области электроники 
                  и автоматизированных систем управления технологическими процессами (АСУ ТП), но и охватываем широкий спектр оборудования по индивидуальным запросам наших клиентов. 
                  Мы стремимся обеспечить высокое качество услуг и удовлетворить потребности каждого клиента, предлагая решения, соответствующие их специфическим требованиям.
              </p>
              <p className="mx-auto mt-4 text-xl text-left ">
                  В нашу компетенцию входят решения в подборе и поставках оригинальной импортной электротехнической продукции следующих брендов:
              </p>
          
             <div className="w-full">
                         <div className="grid sm:grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 gap-2 pb-4">
                    
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">Phoenix Contact</div>
                        </div>
                    </div>
                    
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">AB Quality</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">SIEMENS</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">Schneider Electric</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">WIKA</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">Motorolla</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">KRONHE</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">BECKHOFF</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">PEPPERL+FUCHS</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">Ebmpapst</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">Pilz</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">FESTO</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">APC</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">FLUKE</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">METTLER TOLEDO</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">SICK</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">EH</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">SEW Eurodrive</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">WAGO</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">Lenze</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">CISCO</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">IFM</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">Keyence</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">CHINT Electrec</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">Midea</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">SMC</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">Omron</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">Gree</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">Dji</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">DELTA</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">Mitsubishi</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">XIAOMI</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">Huawei</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">Yokogawa</div>
                        </div>
                    </div>
                    <div className="relative rounded overflow-hidden bg-gray-200 cursor-pointer">
                        <div className="relative flex group">
                            <div className="bg-gray-200 text-3xl font-bold text-black px-2 py-2 ">Fanuc</div>
                        </div>
                    </div>

                </div>
                </div>
                                    
                </div>
            
        </section>
        <section className="relative">
          <ContactForm />
        </section>
        <Footer />
    </div>
  </div>
);
}
    

export default Kipia;