// Catalog component 
import React, { useEffect } from "react";
import { Link } from 'react-router-dom';

import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";

import backgroundimg from '../assets/backgroundimg.webp';
import indimg from '../assets/ind.webp';

import zadvklin from '../assets/zadv-klin.jpg';


const ZadvizhkiKlinovye = () => {
  useEffect(() => {
    document.title = 'Задвижки клиновые - ИТЦ Квазар';
  }, []);
    return(
      <div className="App">
        <Navigation />
          <div className="relative">
            {/* background image */}
            <div className="absolute inset-0 z-0"
              style={{
              backgroundImage: `url(${backgroundimg})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',}}
            ></div>

        {/* section starts here */}

          <section className="relative md:block hidden">
            <div className='w-full h-[200px] bg-gray-900/90 absolute'>
              <img className='w-full h-full object-cover mix-blend-overlay' src={indimg} alt="indimg" />
            </div>
            <div className='max-w-[1240px] mx-auto text-white relative'>
              <div className='px-4 py-12 md:block hidden'>
                <h3 className='text-5xl font-bold py-6 text-center'><Link to='/catalog'><span className="underline">Запорно-регулирующая арматура</span></Link></h3>
              </div>
                <div className='w-full h-[50px] relative'>
              </div>
            </div>
          </section>
            
          <section className="relative">
            <div className="container max-w-xl p-6 mx-auto space-y-12 lg:px-8 lg:max-w-7xl">
            <h2 className="text-3xl font-bold text-left sm:text-5xl">Задвижки клиновые</h2>
              <div>
                <p className="mx-auto mt-4 text-xl text-left ">
                Одним из наиболее распространенных видов трубопроводной запорной арматуры является клиновая задвижка, которая сочетает в себе функциональность и эффективность. 
                Благодаря своей простой конструкции, клиновые задвижки обладают высокой износостойкостью и надежностью.
                    <br />
                    <br />
                    Задвижки клиновые широко применяются на различных транспортных и технологических трубопроводах для регулирования передачи жидких, газообразных и сыпучих материалов. 
                    Их универсальность делает клиновые задвижки идеальным выбором для многих отраслей, обеспечивая надежное и эффективное управление потоками в трубопроводных системах.
                  </p>
              </div>
              <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
                <div>
                  <div className="mt-4 space-y-12">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center w-12 h-12 rounded-md">
                          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                            <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                          </svg> 
                        </div>
                      </div> 
                     <p className="mt-2 ">В клиновой задвижке сёдла, расположенные в корпусе, имеют небольшой угол наклона друг к другу. Это конструктивное решение позволяет затвору, 
                      который представляет собой клин (может быть жестким, упругим или двухдисковым), эффективно запирать поток. В положении «закрыто» клин плотно входит в пространство между сёдлами до упора, 
                      обеспечивая надежную герметичность и предотвращая утечки рабочей среды.</p>
                  </div>
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 rounded-md">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                        </svg> 
                      </div>
                    </div>
                    <p className="mt-2">В исполнении «жесткого клина» герметичность достигается за счет высокой точности обработки, что позволяет углу клина точно совпадать с углом между сёдлами корпуса. 
                      Это обеспечивает надежное запирание потока и минимизирует вероятность утечек. Однако у этой конструкции есть и недостатки. Одним из основных является возможность заклинивания затвора, которое может возникнуть из-за колебаний температуры рабочей среды, 
                      а также износа и коррозии уплотнительных поверхностей. Поэтому при проектировании и эксплуатации клиновых задвижек необходимо учитывать эти риски и, при необходимости, применять дополнительные меры для предотвращения заклинивания.</p>
                  </div>
                  <div className="flex">
                  <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 rounded-md">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                        </svg> 
                      </div>
                    </div>
                    <p className="mt-2">В исполнении «двухдискового клина» клин формируется двумя дисками, которые расположены под углом друг к другу и жестко сцеплены между собой. Эта конструкция позволяет дискам немного подстраиваться относительно сёдел корпуса, 
                      что помогает нивелировать некоторые погрешности сёдел и обеспечивает герметичность задвижки в положении «закрыто». Двухдисковый клиновой затвор обладает рядом преимуществ: у него меньшая вероятность заклинивания, меньший износ уплотнительных поверхностей и меньшее усилие, необходимое для закрытия. 
                      Благодаря этим характеристикам задвижки с двухдисковым клином обеспечивают более высокую герметичность запорного органа, достигая классов герметичности до класса А.</p>
                  </div>
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 rounded-md">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                        </svg> 
                      </div>
                    </div>
                    <p className="mt-2">В варианте «упругого клина» запорный элемент представляет собой полуразрезанный или разрезанный клин, где обе части связаны между собой упругим элементом. 
                      Этот элемент позволяет клину изгибаться при закрывании задвижки, что способствует сглаживанию недостатков сёдел корпуса и повышает герметичность запорного органа.</p>
                  </div>
                </div>
              </div>
              <div aria-hidden="true" className="mt-10 lg:mt-0">
                <img src={zadvklin} alt="zadvklin" className="mx-auto rounded-lg shadow-lg dark-bg-gray-500" />
              </div>
            </div>
            </div>
          </section>
          <section className="relative">
            <ContactForm />
          </section>
          <Footer />
      </div>
    </div>
  );
}
    

export default ZadvizhkiKlinovye;