// Catalog component 
import React, { useEffect } from "react";
import { Link } from 'react-router-dom';

import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";

import backgroundimg from '../assets/backgroundimg.webp';
import indimg from '../assets/ind.webp';

import trvalve from '../assets/trvalve.jpg';




const ZatvoryDiskovyeTr = () => {
  useEffect(() => {
    document.title = 'Затворы дисковые с тройным эксцентрисистетом - ИТЦ Квазар';
  }, []);
    return(
      <div className="App">
        <Navigation />
          <div className="relative">
            {/* background image */}
            <div className="absolute inset-0 z-0"
              style={{
              backgroundImage: `url(${backgroundimg})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',}}
            ></div>

        {/* section starts here */}

          <section className="relative md:block hidden">
            <div className='w-full h-[200px] bg-gray-900/90 absolute'>
              <img className='w-full h-full object-cover mix-blend-overlay' src={indimg} alt="indimg" />
            </div>
            <div className='max-w-[1240px] mx-auto text-white relative'>
              <div className='px-4 py-12 md:block hidden'>
                <h4 className='text-3xl font-bold py-6 text-center'><Link to='/catalog'><span className="underline">Запорно-регулирующая арматура</span></Link> / <Link to='/catalog/zatvory-diskovye-povorotnye'><span className="underline">Затворы дисковые поворотныe</span></Link></h4>
              </div>
                <div className='w-full h-[50px] relative'>
              </div>
            </div>
          </section>
                 
          <section className="relative">
            <div className="container max-w-xl p-6 mx-auto space-y-12 lg:px-8 lg:max-w-7xl">
              <div>
                <h2 className="text-3xl font-bold text-left sm:text-5xl">Затворы дисковые с тройным эксцентрисистетом</h2>
                <br />
                <br />
                <p className="mx-auto mt-4 text-xl text-left ">Поворотные дисковые затворы с тройным эксцентриситетом находят широкое применение в промышленных трубопроводах, особенно на предприятиях, где предъявляются высокие требования к безопасности процессов, 
                  надежности оборудования и простоте его обслуживания. Основными потребителями таких затворов являются компании в сфере тепловой энергетики, что обусловлено их способностью эффективно работать в условиях с особыми характеристиками рабочей среды.
                  Эти затворы способны справляться с экстремальными условиями, такими как температура до 700 °C и давление жидкости и пара до 100 бар
                    <br />
                </p>
              </div>
              <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
                <div>
                  <div className="mt-4 space-y-12">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center w-12 h-12 rounded-md">
                          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                            <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                          </svg> 
                        </div>
                      </div> 
                      <p className="mt-2 "> Трехэксцентриковая конструкция обеспечивает надежное уплотнение и минимизирует износ, что делает их идеальными для использования в высоконагруженных системах. 
                        Благодаря своим уникальным характеристикам, такие затворы обеспечивают не только безопасность, но и высокую эффективность работы трубопроводного оборудования.</p>
                  </div>
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 rounded-md">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                        </svg> 
                      </div>
                    </div>
                    <p className="mt-2">В трехэксцентриковых затворах реализовано смещение штока относительно оси затворного дискового элемента и центральной оси трубы, что является ключевым аспектом их конструкции.
                    Коническое затворное звено плавно закрывает проход, что позволяет минимизировать деформационные нагрузки на комплектующие. 
                    Благодаря такой конструкции, затворы могут достигать максимального прижатия без необходимости применения большого крутящего момента, что значительно упрощает управление и повышает надежность работы.
                    </p>
                  </div>
                  <div className="flex">
                  <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 rounded-md">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                        </svg> 
                      </div>
                    </div>
                    <p className="mt-2">Заслонка и корпус трехэксцентриковых затворов изготавливаются из металла, что обеспечивает надежную базу для фиксации комплекта уплотняющих колец, выполненных из легированной стали и графита. 
                      Это решение позволяет игнорировать амплитуду теплового расширения и устраняет ограничения по максимальному нагреву транспортируемых жидкостей и газов.</p>
                  </div>
                  <div className="flex">
                  <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 rounded-md">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                        </svg> 
                      </div>
                    </div>
                    <p className="mt-2">Долговечность седла затвора обеспечивается за счет использования коррозионно-стойкого материала, который производится методом наплавления. 
                      В некоторых моделях седло представлено в виде кольца из нержавеющей стали, что делает его расходным компонентом, легко заменяемым в случае износа.</p>
                  </div>
      
                </div>
              </div>
              <div aria-hidden="true" className="mt-10 lg:mt-0">
                <img src={trvalve} alt="trvalve" className="mx-auto rounded-lg shadow-lg dark-bg-gray-500" />
              </div>
            </div>
            </div>
          </section>
          {/* <ProductTableDiskDv /> */}
          <section className="relative">
            <ContactForm />
          </section>
          <Footer />
      </div>
    </div>
  );
}
    

export default ZatvoryDiskovyeTr;