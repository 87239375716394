const ProductTableDiskSym = () => {
    return (
<section className="relative text-gray-700 body-font overflow-hidden">
  <div className="container max-w-xl lg:max-w-7xl px-5 py-12 mx-auto">
    <p className="mx-auto mt-4 text-xl text-left">Технические характеристики<br /></p>
    <div className="flex flex-wrap">
    <div className="w-1/4 mt-4 block">
      <div className="mt-px border-t border-gray-300 border-b border-l rounded-tl-lg rounded-bl-lg overflow-hidden">
        <p className="bg-gray-300 text-gray-900 h-12 text-center px-4 flex items-center justify-start -mt-px">Характеристики</p>
        <p className="text-gray-900 h-20 text-center px-4 flex items-center justify-start">Основные рабочие среды</p>
        <p className="bg-gray-100 text-gray-900 h-12  text-center px-4 flex items-center justify-start">Изготовление и поставка</p>
        <p className="text-gray-900 h-12  text-center px-4 flex items-center justify-start">Тип конструкции</p>
        <p className="bg-gray-100 text-gray-900 h-12  text-center px-4 flex items-center justify-start">Диаметр номинальный</p>
        <p className="text-gray-900 h-12  text-center px-4 flex items-center justify-start">Давление номинальное</p>
        <p className="bg-gray-100 text-gray-900 h-20 text-center px-4 flex items-center justify-start">Температура рабочей среды</p>
        <p className="text-gray-900 h-28  text-center px-4 flex items-center justify-start">Управление</p>
        <p className="bg-gray-100 text-gray-900 h-12  text-center px-4 flex items-center justify-start">Тип седла</p>
        <p className="text-gray-900 h-12  text-center px-4 flex items-center justify-start">Класс герметичности</p>
        <p className="bg-gray-100 text-gray-900 h-36  text-center px-4 flex items-center justify-start">Присоединение</p>
        <p className="text-gray-900 h-12  text-center px-4 flex items-center justify-start">Установочное положение</p>
        <p className="bg-gray-100 text-gray-900 h-12  text-center px-4 flex items-center justify-start">Направление подачи рабочей среды</p>
        <p className="text-gray-900 h-12  text-center px-4 flex items-center justify-start">Скорость потока рабочей среды</p>
        <p className="bg-gray-100 text-gray-900 h-20 text-center px-4 flex items-center justify-start">Расходная характеристика затворов</p>
        <p className="text-gray-900 h-12  text-center px-4 flex items-center justify-start">Климатическое исполнение</p>
        <p className="bg-gray-100 text-gray-900 h-40 text-center px-4 flex items-center justify-start">Стандартный материал конструкции</p>
      </div>
    </div>
    <div className="flex w-3/4 flex-wrap">
      <div className="-mt-px w-full mb-10 border-4 rounded-lg border-indigo-500 relative">
        <div className="p-[0.45rem] text-center border-t border-gray-300">
        </div>
        <p className="bg-gray-300 text-gray-600 h-12 text-center px-2 flex items-center -mt-px justify-center border-t border-gray-300">Показатель</p>
        <p className="h-20 text-gray-600 text-center leading-relaxed flex items-center justify-center ">
          агрессивные: кислоты и щелочи, аммиак и нефтепродукты, углеводороды и природный газ, морская вода и спирты;
          неагрессивные среды: воздух, пар, вода, газообразный аммиак и нефтепродукты.
        </p>
        <p className="h-12 bg-gray-100 text-gray-600 text-center leading-relaxed flex items-center justify-center">
          по ГОСТ 13547—2015 Арматура трубопроводная ЗАТВОРЫ ДИСКОВЫЕ
        </p>
        
        <p className="h-12  text-gray-600 text-center leading-relaxed flex items-center justify-center">
          Затворы со съемным или монолитным седлом из эластомера и симметричным диском без покрытия
        </p>
        <p className="h-12 bg-gray-100 text-gray-600 text-center leading-relaxed flex items-center justify-center">
          DN 50 - DN 1200
        </p>
        <p className="h-12  text-gray-600 text-center leading-relaxed flex items-center justify-center">
            PN 6, 10 для затворов DN 50 - DN 1200 PN 16 для затворов DN 50 - DN 600
        </p>
        <p className="h-20 bg-gray-100 text-gray-600 text-center leading-relaxed flex items-center justify-center">
            до +200 °С (в зависимости от материала конструкции)
        </p>
        <p className="h-28  text-gray-600 text-center leading-relaxed flex items-center justify-center">
        ручное – рукоятка - DN 50 - DN 250<br />
        ручное – через редуктор - DN 50 - DN 1200<br />
        электропривод или пневмопривод - DN 50 - DN 1200
        </p>
        <p className="h-12 bg-gray-100 text-gray-600 text-center leading-relaxed flex items-center justify-center">
        заменяемое для DN 50 - DN 600 PN 6, 10<br />
        монолитное для DN 50 - DN 1200 PN 6, 10, 16
        </p>
        <p className="h-12  text-gray-600 text-center leading-relaxed flex items-center justify-center">
            ГОСТ Р 54808-2011- класс А
        </p>
        <p className="h-36 bg-gray-100 text-gray-600 text-center leading-relaxed flex items-center justify-center">
        Бесфланцевое стяжное, между фланцами трубопровода. 
        Присоединительные фланцы трубопровода стальные приварные по ГОСТ 12820-80 или ГОСТ 12821-80.   
        Присоединительные размеры и размеры уплотнительных поверхностей фланцев трубопровода – по ГОСТ 12815, исполнение 1, ряд 2.
        </p>
        <p className="h-12  text-gray-600 text-center leading-relaxed flex items-center justify-center">
        любое, кроме положения «электроприводом вниз» для электроприводных затворов 
        </p>
        <p className="h-12 bg-gray-100 text-gray-600 text-center leading-relaxed flex items-center justify-center">
        любое
        </p>
        <p className="h-12  text-gray-600 text-center leading-relaxed flex items-center justify-center">
        для газообразной среды - 80м/с, для жидкой среды - 5м/с
        </p>
        <p className="h-20 bg-gray-100 text-gray-600 text-center leading-relaxed flex items-center justify-center">
        Равнопроцентная, при углах открытия диска от 20º  до 70º . 
        При углах открытия до 60º  -70º затворы могут использоватся для дросселирования потока рабочей среды.
        </p>
        <p className="h-12  text-gray-600 text-center leading-relaxed flex items-center justify-center">
            У, УХЛ, Т, ТМ, ТВ по ГОСТ 15150-69
        </p>
        <p className="h-40 bg-gray-100 text-gray-600 text-center leading-relaxed flex items-center justify-center">
        корпус: углеродистая сталь/нержавеющая сталь/нержавеющая сталь с содержанием молибдена<br /> 
        диск: углеродистая сталь/нержавеющая сталь/нержавеющая сталь с содержанием молибдена и другие сплавы<br /> 
        шток: легированная сталь/нержавеющая сталь<br /> 
        седло: ЭПДМ/Витон/Нитрил/Неопрен/Гипалон/Силикон*
        </p>
        <div className="p-2 text-center border-t border-gray-300">
        </div>
      </div>
    </div>
  </div>
  </div>
</section>
    );
}
export default ProductTableDiskSym