// Catalog component 
import React, { useEffect } from "react";
import { Link } from 'react-router-dom';

import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";

import backgroundimg from '../assets/backgroundimg.webp';
import indimg from '../assets/ind.webp';

import flanec from '../assets/flanec.jpg';


const Flancy = () => {
  useEffect(() => {
    document.title = 'Фланцы - ИТЦ Квазар';
  }, []);
    return(
      <div className="App">
        <Navigation />
          <div className="relative">
            {/* background image */}
            <div className="absolute inset-0 z-0"
              style={{
              backgroundImage: `url(${backgroundimg})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',}}
            ></div>

        {/* section starts here */}

          <section className="relative md:block hidden">
            <div className='w-full h-[200px] bg-gray-900/90 absolute'>
              <img className='w-full h-full object-cover mix-blend-overlay' src={indimg} alt="indimg" />
            </div>
            <div className='max-w-[1240px] mx-auto text-white relative'>
              <div className='px-4 py-12 md:block hidden'>
                <h3 className='text-5xl font-bold py-6 text-center'><Link to='/catalog'><span className="underline">Запорно-регулирующая арматура</span></Link></h3>
              </div>
                <div className='w-full h-[50px] relative'>
              </div>
            </div>
          </section>
            
          <section className="relative">
            <div className="container max-w-xl p-6 mx-auto space-y-12 lg:px-8 lg:max-w-7xl">
            <h2 className="text-3xl font-bold text-left sm:text-5xl">Фланцы</h2>
              <div>
                <p className="mx-auto mt-4 text-xl text-left ">Фланец — это важная деталь трубопровода, которая может иметь плоское или дисковое строение и оснащена равномерно расположенными отверстиями для болтов или шпилек. 
                  Основное назначение фланца — обеспечить герметичное и прочное соединение труб, что критично для эффективной работы трубопроводных систем.
                    <br />
                    <br />
                    Фланцы используются для монтажа отдельных частей трубопровода, соединения труб с различной арматурой и оборудованием, а также для стыковки отдельных участков между собой. Они могут применяться для присоединения труб к оборудованию, 
                    емкостям и машинам, что делает их универсальными компонентами в системах трубопроводов.
                  </p>
              </div>
              <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
                <div>
                  <div className="mt-4 space-y-12">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center w-12 h-12 rounded-md">
                          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                            <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                          </svg> 
                        </div>
                      </div> 
                     <p className="mt-2 ">Изделие представляет собой диск или кольцо с равномерно расположенными отверстиями. Важно, чтобы диаметр внутреннего отверстия фланца полностью совпадал с аналогичным диаметром трубы. 
                      Это обеспечивает легкость и быстроту процесса монтажа, а также гарантирует надежную стыковку без возникновения проблем. 
                      Точное соответствие размеров позволяет избежать утечек и обеспечивает герметичность соединения, что критично для эффективной работы трубопроводной системы.</p>
                  </div>
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 rounded-md">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                        </svg> 
                      </div>
                    </div>
                    <p className="mt-2">По периметру пластины расположены дополнительные отверстия меньшего диаметра, предназначенные для болтового крепления. Фиксация фланца осуществляется с помощью болтов, что обеспечивает высокие параметры прочности и надежности соединения. 
                      Такой способ крепления позволяет равномерно распределять нагрузку и предотвращает возможные деформации, 
                      что делает соединение устойчивым к различным воздействиям и гарантирует долговечность работы трубопроводной системы.</p>
                  </div>
                  <div className="flex">
                  <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 rounded-md">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                        </svg> 
                      </div>
                    </div>
                    <p className="mt-2">Фланцы изготавливаются из высококачественной стали, что обеспечивает их долговечность и надежность. В зависимости от сферы применения могут использоваться углеродистая или нержавеющая сталь, причем нержавеющие фланцы чаще применяются в стандартных водопроводах. 
                      Если же речь идет о системах, работающих под значительным давлением, при высокой температуре или в агрессивной среде (например, пар, нефть, газ), предпочтение отдается фланцам из легированной стали. 
                      Такие материалы обеспечивают необходимую прочность и устойчивость к коррозии, что критично для безопасной и эффективной работы трубопроводных систем.</p>
                  </div>
                  
                </div>
              </div>
              <div aria-hidden="true" className="mt-10 lg:mt-0">
                <img src={flanec} alt="flanec" className="mx-auto rounded-lg shadow-lg dark-bg-gray-500" />
              </div>
            </div>
            </div>
          </section>
          <section className="relative">
            <ContactForm />
          </section>
          <Footer />
      </div>
    </div>
  );
}
    

export default Flancy;