// Catalog component 
import React, { useEffect } from "react";
import { Link } from 'react-router-dom';

import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";

import backgroundimg from '../assets/backgroundimg.webp';
import indimg from '../assets/ind.webp';

import klapzap from '../assets/klap-zap.jpg';


const KlapanyZapornye = () => {
  useEffect(() => {
    document.title = 'Клапаны запорные - ИТЦ Квазар';
  }, []);
    return(
      <div className="App">
        <Navigation />
          <div className="relative">
            {/* background image */}
            <div className="absolute inset-0 z-0"
              style={{
              backgroundImage: `url(${backgroundimg})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',}}
            ></div>

        {/* section starts here */}

          <section className="relative md:block hidden">
            <div className='w-full h-[200px] bg-gray-900/90 absolute'>
              <img className='w-full h-full object-cover mix-blend-overlay' src={indimg} alt="indimg" />
            </div>
            <div className='max-w-[1240px] mx-auto text-white relative'>
              <div className='px-4 py-12 md:block hidden'>
                <h3 className='text-5xl font-bold py-6 text-center'><Link to='/catalog'><span className="underline">Запорно-регулирующая арматура</span></Link></h3>
              </div>
                <div className='w-full h-[50px] relative'>
              </div>
            </div>
          </section>
            
          <section className="relative">
            <div className="container max-w-xl p-6 mx-auto space-y-12 lg:px-8 lg:max-w-7xl">
            <h2 className="text-3xl font-bold text-left sm:text-5xl">Клапаны запорные</h2>
              <div>
                <p className="mx-auto mt-4 text-xl text-left ">Запорные клапаны относятся к запорной арматуре двухпозиционного действия, 
                  что означает, что они могут использоваться исключительно для включения или отключения потока в трубопроводах. Эти клапаны предназначены для полного перекрытия или открытия потока рабочей среды.
                    <br />
                    <br />
                    Запорные клапаны функционируют за счет возвратно-поступательного перемещения запорного органа вдоль оси потока, которое происходит перпендикулярно к плоскости седла. Это обеспечивает надежное и эффективное управление потоком, 
                    позволяя полностью остановить или восстановить его в трубопроводной системе. Благодаря своей конструкции, запорные клапаны находят широкое применение в различных отраслях, где требуется надежное управление потоками жидкостей и газов.
                  </p>
              </div>
              <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
                <div>
                  <div className="mt-4 space-y-12">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center w-12 h-12 rounded-md">
                          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                            <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                          </svg> 
                        </div>
                      </div> 
                     <p className="mt-2 ">Основные преимущества дисковых затворов заключаются в их простой конструкции и низкой стоимости.
                        Обычно затвор состоит из небольшого количества деталей, что облегчает процесс ремонта и замену вышедших из строя компонентов. В затворе всего четыре основных элемента: корпус, диск, поворотный вал (шпиндель) и седло.</p>
                  </div>
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 rounded-md">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                        </svg> 
                      </div>
                    </div>
                    <p className="mt-2">Клапаны выпускаются с запорными органами двух типов: с коническими и плоскими рабочими поверхностями. Управление этими клапанами может осуществляться различными способами, 
                      включая ручное управление с помощью маховика или дистанционное управление с использованием электропривода.</p>
                  </div>
                  <div className="flex">
                  <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 rounded-md">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                        </svg> 
                      </div>
                    </div>
                    <p className="mt-2">Электроприводы могут быть как встроенными (расположенными непосредственно на клапанах), так и отдельными (колонковыми). В случае использования колонкового электропривода соединение с клапаном осуществляется через штангу 
                      с шарнирами и приводную головку, которая может иметь цилиндрическое или коническое зацепление.</p>
                  </div>
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 rounded-md">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                        </svg> 
                      </div>
                    </div>
                    <p className="mt-2">При установке клапана со встроенным электроприводом важно предусмотреть его дополнительное раскрепление относительно строительных конструкций, чтобы обеспечить надежность и безопасность в эксплуатации. 
                      Это позволяет избежать возможных вибраций и нагрузок, которые могут повлиять на работу клапана и его приводного механизма.</p>
                  </div>
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 rounded-md">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                        </svg> 
                      </div>
                    </div>
                  <p className="mt-2">Клапаны в энергетических установках высоких параметров обычно применяются до диаметра условного прохода Ду 65, тогда как для средних и низких параметров их использование может достигать Ду 150. 
                    Основной причиной ограничения применения запорных клапанов является их высокий коэффициент гидравлического сопротивления, который может негативно влиять на эффективность работы системы.</p>
                  </div>
                </div>
              </div>
              <div aria-hidden="true" className="mt-10 lg:mt-0">
                <img src={klapzap} alt="klapzap" className="mx-auto rounded-lg shadow-lg dark-bg-gray-500" />
              </div>
            </div>
            </div>
          </section>
          <section className="relative">
            <ContactForm />
          </section>
          <Footer />
      </div>
    </div>
  );
}
    

export default KlapanyZapornye;