import { useState } from 'react';
import axios from "axios";

const ContactForm = () => {
    
    const [status, setStatus] = useState("Отправить");
    const [phone, setPhone] = useState("");  
    const [fio, setFio] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus("Отправка...");
        try {
            await axios.post("https://itckvazar.ru:5000/contact", {
                from: "site@itckvazar.ru",  
                fio,
                phone,
                message
            });

        setStatus("Отправлено");
      } catch(err) {
        alert(err);
      }
  };

    return (
<div className="relative py-10 flex items-top justify-center bg-gray-100 dark:bg-gray-900 sm:items-center sm:pt-0">
    <div className="max-w-6xl mx-auto sm:px-6 lg:px-8">
        <div className="mt-8 overflow-hidden">
            <div className="grid grid-cols-1 md:grid-cols-2">
                <div className="p-6 mr-2 bg-gray-100 dark:bg-gray-800 sm:rounded-lg">
                    <h1 className="text-4xl sm:text-5xl text-gray-800 dark:text-white font-extrabold tracking-tight">
                        Направьте нам запрос!
                    </h1>
                    <p className="text-normal text-lg sm:text-2xl font-medium text-gray-600 dark:text-gray-400 mt-2">
                        Заполните форму обратной связи или свяжитесь с нами по телефону, либо напишите нам на эл. почту
                    </p>

                    <div className="flex items-center mt-8 text-gray-600 dark:text-gray-400">
                      
                    </div>

                    <div className="flex items-center mt-4 text-gray-600 dark:text-gray-400">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" className="w-8 h-8 text-gray-500">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                        </svg>
                        <div className="ml-4 text-xl tracking-wide font-semibold w-60">
                        +7 (495) 777-18-83
                        </div>
                    </div>

                    <div className="flex items-center mt-2 text-gray-600 dark:text-gray-400">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" className="w-8 h-8 text-gray-500">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                        </svg>
                        <div className="ml-4 text-md tracking-wide font-semibold w-40">
                            sales@itckvazar.ru
                        </div>
                    </div>
                </div>

                <form onSubmit={handleSubmit} className="p-6 flex flex-col justify-center">
                    <div className="flex flex-col">
                        <label for="fio" className="hidden">Ваше имя</label>
                        <input id="fio" value={fio} onChange={(e) => setFio(e.target.value)} type="text" placeholder="Ваше имя" className="w-100 mt-2 py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-blue-600 dark:border-gray-700 text-gray-800 font-semibold focus:border-gray-400 focus:outline-none" />
                    </div>

                    <div className="flex flex-col mt-2">
                        <label for="phone" className="hidden">Контактный номер</label>
                        <input id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} type="phone" name="phone" placeholder="Контактный номер" className="w-100 mt-2 py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-blue-600 dark:border-gray-700 text-gray-800 font-semibold focus:border-gray-400 focus:outline-none" />
                    </div>

                    <div className="flex flex-col mt-2">
                        <label for="message" className="hidden">Сообщение</label>
                        <textarea id="message" value={message} onChange={(e) => setMessage(e.target.value)} name="message" placeholder="Ваше сообщение" className="w-100 mt-2 py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-blue-600 dark:border-gray-700 text-gray-800 font-semibold focus:border-gray-400 focus:outline-none"></textarea>
                    </div>

                    <button type="submit" className="md:w-32 bg-gray-200 text-blue-600 font-bold py-3 px-6 rounded-lg mt-3 hover:bg-gray-300 transition ease-in-out duration-300">
                        {status}
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>
    );
}
export default ContactForm