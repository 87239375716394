// Catalog component 
import React, { useEffect } from "react";
import { Link } from 'react-router-dom';

import Navigation from "../components/Navigation";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";

import backgroundimg from '../assets/backgroundimg.webp';
import indimg from '../assets/ind.webp';

import eldvig from '../assets/eldvig.jpg';


const Eldvig = () => {
  useEffect(() => {
    document.title = 'Электродвигатели, частотно-регулируемые приводы и автоматика - ИТЦ Квазар';
  }, []);
    return(
      <div className="App">
        <Navigation />
          <div className="relative">
            {/* background image */}
            <div className="absolute inset-0 z-0"
              style={{
              backgroundImage: `url(${backgroundimg})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',}}
            ></div>

          <section className="relative md:block hidden">
            <div className='w-full h-[200px] bg-gray-900/90 absolute'>
              <img className='w-full h-full object-cover mix-blend-overlay' src={indimg} alt="indimg" />
            </div>
            <div className='max-w-[1240px] mx-auto text-white relative'>
              <div className='px-4 py-12 md:block hidden'>
                <h3 className='text-5xl font-bold py-6 text-center'><Link to='/catalog'><span className="underline">Электротехнические изделия <br />параллельного импорта</span></Link></h3>
              </div>
                <div className='w-full h-[50px] relative'>
              </div>
            </div>
          </section>
            
          <section className="relative">
            <div className="container max-w-xl p-6 mx-auto space-y-12 lg:px-8 lg:max-w-7xl">
            <h2 className="text-3xl font-bold text-left sm:text-5xl">Электродвигатели, частотно-регулируемые приводы и автоматика</h2>
              <div>
                <p className="mx-auto mt-4 text-xl text-left ">
                Электродвигатель — это высокотехнологичное устройство, которое преобразует электрическую энергию в механическую работу. Его функционирование основано на принципах магнетизма, что связано с использованием различных типов магнитов, 
                таких как постоянные магниты и электромагниты, а также материалов с магнитными свойствами. Благодаря этому процессу электродвигатель способен эффективно выполнять задачи в самых разных областях, от бытовых приборов до промышленных машин.
                </p>
                <p className="mx-auto mt-4 text-xl text-left ">
                Главный технологический процесс, обеспечивающий постоянную работу электродвигателя, заключается в создании момента вращения за счет подачи напряжения на катушки ротора. Этот процесс происходит в единой системе, 
                состоящей из нескольких ключевых компонентов: коллектора, щеткообразного механизма, ротора и статора.
                </p>
              </div>
              <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
                <div>
                  <div className="mt-4 space-y-12">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center w-12 h-12 rounded-md">
                          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                            <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                          </svg> 
                        </div>
                      </div> 
                     <p className="mt-2 ">Коллектор — это важный компонент электродвигателя, который обеспечивает автоматическое переключение ротора. Он состоит из двух ламелей, 
                      которые крепятся на валу ротора, и двух щеток, которые обеспечивают токосъемные контакты.</p>
                  </div>
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 rounded-md">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                        </svg> 
                      </div>
                    </div>
                    <p className="mt-2">Ротор — это подвижная составляющая электродвигателя, которая приводится в действие магнитным полем, создаваемым статором. 
                      Он вращается вокруг своего вала и выполняет основную функцию преобразования электрической энергии в механическую.</p>
                  </div>
                  <div className="flex">
                  <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 rounded-md">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                        </svg> 
                      </div>
                    </div>
                    <p className="mt-2">Статор — это важный элемент электродвигателя, который может быть как стационарным, так и подвижным. Его часто называют индуктором. 
                      Он состоит из нескольких обмоток, обладающих сменной полярностью, что позволяет ему создавать магнитное поле во время работы устройства. 
                      Обмотки статора подключаются к источнику переменного тока, что приводит к изменению направления тока и, соответственно, полярности магнитного поля. 
                      Это взаимодействие с ротором создает необходимый вращающий момент. В традиционной конструкции статора обычно присутствует две пары главных полюсов, 
                      но в зависимости от требований к производительности могут добавляться дополнительные пары полюсов для повышения эффективности взаимодействия с ротором. 
                      Таким образом, статор играет ключевую роль в обеспечении работы электродвигателя.</p>
                  </div>
                  
                </div>
              </div>
              <div aria-hidden="true" className="mt-10 lg:mt-0">
                <img src={eldvig} alt="eldvig" className="mx-auto rounded-lg shadow-lg dark-bg-gray-500" />
              </div>
            </div>
            </div>
          </section>
          <section className="relative">
            <ContactForm />
          </section>
          <Footer />
      </div>
    </div>
  );
}
    

export default Eldvig;