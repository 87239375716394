// Home component 
import React, { useEffect } from "react";

import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import Grid from "./components/Grid";
import Steps from "./components/Steps";
import ContactForm from "./components/ContactForm";

import backgroundimg from './assets/backgroundimg.webp';
import indimg from './assets/ind.webp';

const About = () => {
  useEffect(() => {
    document.title = 'ИТЦ Квазар - поставщик запорной арматуры и электротехнческих ихделий';
  }, []);
    return(
      <div className="App">
        < Navigation />
          <div className="relative">
            {/* background image */}
            <div className="absolute inset-0 z-0"
              style={{
              backgroundImage: `url(${backgroundimg})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',}}
            ></div>

        {/* section starts here */}

          <section className="md:block hidden relative pb-12">
            <div className='w-full h-[350px] bg-gray-900/90 absolute'>
              <img className='w-full h-full object-cover mix-blend-overlay' src={indimg} alt="indimg" />
            </div>
            <div className='max-w-[1240px] mx-auto text-white relative'>
              <div className='px-4 py-4'>
                <h2 className='text-3xl pt-8 text-slate-200 uppercase text-center'>Инженерно-Технический Центр</h2>
                <h3 className='text-5xl font-bold py-6 text-center'>«КВАЗАР»</h3>
                <p className='py-4 text-xl text-slate-200 text-center'>
                ООО ИТЦ «Квазар» — промышленная инжиниринговая компания, специализирующаяся на комплексных поставках запорно-регулирующей арматуры, деталей трубопроводов и нестандартных металлоконструкций. 
                Также мы предлагаем высоковольтную и низковольтную электротехническую продукцию от европейских производителей. 
                Кроме того, компания занимается поставками товаров других групп материально-технического обеспечения.
                </p>
              </div>
            </div>
          </section>
          <section className="sm:block md:hidden relative pb-4">
            <p className='p-4 text-xl text-gray-900 text-left'>
                ООО ИТЦ «Квазар» — промышленная инжиниринговая компания, специализирующаяся на комплексных поставках запорно-регулирующей арматуры, деталей трубопроводов и нестандартных металлоконструкций. 
                Также мы предлагаем высоковольтную и низковольтную электротехническую продукцию от европейских производителей. 
                Кроме того, компания занимается поставками товаров других групп материально-технического обеспечения.
                </p>
          </section>
          <section className="relative">
            <Grid />
          </section>

          <section className="relative">
            <Steps />
          </section>

          {/* <section className="relative">
            <ProductList />
          </section> */}

          <section className="relative">
            <ContactForm />
          </section>
          <Footer />
      </div>
    </div>
  );
}
    

export default About;