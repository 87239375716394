// Catalog component 
import React, { useEffect } from "react";

import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import Grid from "./components/Grid";

import backgroundimg from './assets/backgroundimg.webp';
import indimg from './assets/ind.webp';

const Catalog = () => {
  useEffect(() => {
    document.title = 'ИТЦ Квазар - поставщик запорной арматуры и электротехнческих ихделий';
  }, []);
    return(
      <div className="App">
        <Navigation />
          <div className="relative">
            {/* background image */}
            <div className="absolute inset-0 z-0"
              style={{
              backgroundImage: `url(${backgroundimg})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',}}
            ></div>

        {/* section starts here */}

          <section className="relative md:block hidden">
            <div className='w-full h-[200px] bg-gray-900/90 absolute'>
              <img className='w-full h-full object-cover mix-blend-overlay' src={indimg} alt="indimg" />
            </div>
            <div className='max-w-[1240px] mx-auto text-white relative'>
              <div className='px-4 py-12 md:block hidden'>
                <h3 className='text-5xl font-bold py-6 text-center'>Каталог продукции:</h3>
               {/* <p className='py-4 text-3xl text-slate-300 text-center'>
                Описание каталога продукции
                </p> */}
              </div>
                <div className='w-full h-[50px] relative'>
              </div>
            </div>
          </section>

          <section className="relative">
            <Grid />
          </section>
          <Footer />
      </div>
    </div>
  );
}
    

export default Catalog;