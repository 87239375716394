import React, {useState} from 'react';

import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { FaUserAlt, FaSignInAlt } from 'react-icons/fa';
import { FaPhone } from "react-icons/fa6";
import { CiMail } from "react-icons/ci";
import { Link } from 'react-router-dom';
import logoimg from '../assets/logo.png';




function Navigation() {
    const [nav, setNav] = useState(false)

    const handleClick = () => setNav(!nav)

    const handleClose = () => {
      setNav(false);
    };


  return (  // Navigation bar on large screen
    <div className='grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 place-items-center w-screen bg-gray-100 z-10 relative drop-shadow-lg '>
    
      
      {/* <div className='flex items-center'>
      </div>
      <div className='flex items-center'>
      </div> */}
      <div className='flex p-4'>
        <Link to='/'>
          <img src={logoimg} alt="logoimg" />
        </Link>
      </div>
      <div className='md:hidden mr-4'>
        <div className='gap-4' p-4>            
          <a className="flex text-center cursor-pointer items-center mx-4 text-black hover:text-blue-600">
            <span className="text-lg font-bold">
            +7 (495) 777-18-83
            </span>
          </a>
          <a className="flex text-center cursor-pointer items-center mx-4 text-black hover:text-blue-600">
            <span className="text-lg font-medium">sales@itckvazar.ru</span>
          </a> 
        </div>
      </div>
      <div className='flex' p-4>
        <ul className='hidden text-black md:flex'>
          <Link to='/'>
            <li className='cursor-pointer hover:bg-white hover:text-blue-600 hover:rounded-lg'>
              О компании
            </li>
          </Link>
          <Link to='/catalog'>
            <li className='cursor-pointer relative group hover:bg-white hover:text-blue-600 hover:rounded-lg'>
              Продукция
            </li>
          </Link>
          <Link to='/kontaktnaya-informaciya'>
          <li className='cursor-pointer relative group hover:bg-white hover:text-blue-600 hover:rounded-lg'>
            Контактная информация
          </li>
          </Link>
        </ul>
      </div>
      
      
      <div className='hidden md:flex pr-4 py-3'>
        <div className='gap-4'>            
            <a className="flex text-center cursor-pointer items-center mx-4 text-black hover:text-blue-600">
              <FaPhone className='lg:w-5 lg:h-5 mx-2' />
              <span className="text-lg font-bold">
              +7 (495) 777-18-83
              </span>
            </a>
          <a className="flex text-center cursor-pointer items-center mx-4 text-black hover:text-blue-600">
              <CiMail className='lg:w-5 lg:h-5 mx-2' />  
              <span className="text-lg font-medium">sales@itckvazar.ru</span>
            </a> 
          </div>
        </div>

        

        <div className='md:hidden mr-4 p-4 flex' onClick={handleClick}>
            {!nav ? <MenuIcon className='w-5 text-black' /> : <div className='flex'>
              <XIcon className='w-5 text-black' /> 
            </div>}
          
        </div>

       


        {/* Navigation on small screens */}
        <ul className={!nav ? 'hidden' : ' bg-zinc-200 w-full px-8 flex'}>
            <Link to='/'>
            <li onClick={handleClose} className='border-b-2 border-zinc-300 w-full'>
              О компании
            </li>
            </Link>
            <Link to='/catalog'>
            <li onClick={handleClose} className='border-b-2 border-zinc-300 w-full'>
              Продукция
            </li>
            </Link>
            <Link to='/kontaktnaya-informaciya'>
            <li onClick={handleClose} className='border-b-2 border-zinc-300 w-full'>
              Контакты
            </li>
            </Link>
          </ul>
          
    </div>
  )
}

export default Navigation