const ProductTableDiskSym = () => {
    return (
<section className="relative text-gray-700 body-font overflow-hidden">
  <div className="container max-w-xl lg:max-w-7xl px-5 py-12 mx-auto">
    <p className="mx-auto mt-4 text-xl text-left">Технические характеристики<br /></p>
    <div className="flex flex-wrap">
    <div className="w-1/4 mt-4 block">
      <div className="mt-px border-t border-gray-300 border-b border-l rounded-tl-lg rounded-bl-lg overflow-hidden">
        <p className="bg-gray-300 text-gray-900 h-12 text-left px-4 flex items-center justify-start ">Характеристики</p>
        <p className="text-gray-900 h-12 text-left items-center px-4 flex justify-start">Тип конструкции</p>
        <p className="bg-gray-100 text-gray-900 h-12 text-left items-center px-4 flex justify-start">Изготовление и поставка</p>
        <p className="text-gray-900 h-12 items-center text-left px-4 flex justify-start">Диаметры номинальные</p>
        <p className="bg-gray-100 text-gray-900 h-12 items-center text-left px-4 flex justify-start">Класс герметичности</p>
        <p className="text-gray-900 h-12 items-center text-center px-4 flex justify-start">Температура рабочей среды</p>
        <p className="bg-gray-100 text-gray-900 h-12 items-center text-left px-4 flex justify-start">Стандартные материалы конструкции</p>
        <p className="text-gray-900 h-12 text-left items-center px-4 flex justify-start">Управление</p>
        <p className="bg-gray-100 text-gray-900 h-12 items-center text-left px-4 flex justify-start">Присоединение</p>
        <p className="text-gray-900 h-12 items-center text-left px-4 flex justify-start">Присоединительные фланцы трубопровода</p>
        <p className="bg-gray-100 text-gray-900 h-12 items-center text-left px-4 flex justify-start">Установочное положение</p>
        <p className="text-gray-900 h-12 items-center text-left px-4 flex justify-start">Направление подачи рабочей среды</p>
        <p className="bg-gray-100 text-gray-900 h-12 items-center text-left px-4 flex justify-start">Климатическое исполнение</p>
        <p className="text-gray-900 h-12 items-center text-left px-4 flex justify-start">Крутящий момент, Нм</p>
        <p className="bg-gray-100 text-gray-900 h-12 items-center text-left px-4 flex justify-start">Пропускная способность, м3</p>
        <p className="text-gray-900 h-12 text-left px-4 flex items-center justify-start">Область дросселирования</p>
        <p className="bg-gray-100 text-gray-900 h-12 items-center text-left px-4 flex justify-start">Коэффициент гидросопротивления, не более</p>
        <p className="text-gray-900 h-28 text-left px-4 flex items-center justify-start">Скорость потока рабочей среды должна быть не более</p>
        <p className="bg-gray-100 text-gray-900 h-28  text-left px-4 flex items-center justify-start">Количество оборотов маховика редуктора, необходимые для полного открытия/закрытия затвора</p>
        <p className="text-gray-900 h-28 text-left px-4 flex items-center justify-start">Размеры верхнего фланца под привод</p>
      </div>
    </div>
    <div className="flex w-3/4 flex-wrap">
      
      <div className="-mt-px w-full mb-10 border-4 rounded-lg border-indigo-500 relative">
        <div className="p-[0.45rem] text-center border-t border-gray-300">
        </div>
        <p className="bg-gray-300 text-gray-600 h-12 text-center px-2 flex items-center  justify-center border-t border-gray-300">Показатель</p>
        <p className="h-12 text-gray-600 text-center leading-relaxed flex items-center justify-center ">
        Затворы двухэксцентриковые запорно-регулирующие с эластомерным уплотнением
        </p>
        <p className="h-12 bg-gray-100 text-gray-600 text-center leading-relaxed flex items-center justify-center">
          по ГОСТ 13547—2015 Арматура трубопроводная ЗАТВОРЫ ДИСКОВЫЕ
        </p>
        <p className="h-12  text-gray-600 text-center leading-relaxed flex items-center justify-center">
        DN 100 - DN 2400
        </p>
        <p className="h-12 bg-gray-100 text-gray-600 text-center leading-relaxed flex items-center justify-center">
        А по ГОСТ Р 54808-2011 
        </p>
        <p className="h-12  text-gray-600 text-center leading-relaxed flex items-center justify-center">
        до +150°С в зависимости от материала конструкции в соответствии с таблицей 1
        </p>
        <p className="h-12 bg-gray-100 text-gray-600 text-center leading-relaxed flex items-center justify-center">
        см. таблицу 1
        </p>
        <p className="h-12  text-gray-600 text-center leading-relaxed flex items-center justify-center">
        ручное – через редуктор DN 100 - DN 2400<br />
        электро или пневмопривод DN 100 - DN 2400
        </p>
        <p className="h-12 bg-gray-100 text-gray-600 text-center leading-relaxed flex items-center justify-center">
        межфланцевое
        </p>
        <p className="h-12  text-gray-600 text-center leading-relaxed flex items-center justify-center">
        стальные приварные по ГОСТ 12820-80 или ГОСТ 12821-80
        </p>
        <p className="h-12 bg-gray-100 text-gray-600 text-center leading-relaxed flex items-center justify-center">
        любое, кроме положения «электроприводом вниз» для электроприводных затворов
        </p>
        <p className="h-12  text-gray-600 text-center leading-relaxed flex items-center justify-center">
        однонаправленное, по стрелке на корпусе затвора 
        </p>
        <p className="h-12 bg-gray-100 text-gray-600 text-center leading-relaxed flex items-center justify-center">
        УХЛ, Т, ТМ, ТВ по ГОСТ 15150-69
        </p>
        <p className="h-12  text-gray-600 text-center leading-relaxed flex items-center justify-center">
        -
        </p>
        <p className="h-12 bg-gray-100 text-gray-600 text-center leading-relaxed flex items-center justify-center">
        -
        </p>
        <p className="h-12  text-gray-600 text-center leading-relaxed flex items-center justify-center">
        при углах открытия от 20°C до 70°C затворы могут использоваться для дросселирования потока рабочей среды
        </p>
        <p className="h-12 bg-gray-100 text-gray-600 text-center leading-relaxed flex items-center justify-center">
       -
        </p>
        <p className="h-28  text-gray-600 text-center leading-relaxed flex items-center justify-center">
        1.0~5.0 м/с (жидкость)
        </p>
        <p className="h-28 bg-gray-100 text-gray-600 text-center leading-relaxed flex items-center justify-center">
        -
        </p>
        <p className="h-28  text-gray-600 text-center leading-relaxed flex items-center justify-center">
        в соответствии со стандартом ISO 5211 размеры см. таблицу 6
        </p>
        <div className="p-2 text-center border-t border-gray-300">
        </div>
      </div>
    </div>
  </div>
  </div>
</section>
    );
}
export default ProductTableDiskSym