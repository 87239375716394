// Catalog component 
import React, { useEffect } from "react";
import { Link } from 'react-router-dom';

import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";

import backgroundimg from '../assets/backgroundimg.webp';
import indimg from '../assets/ind.webp';

import gazkran from '../assets/gaz_kran.jpg';


const KranySharGaz = () => {
  useEffect(() => {
    document.title = 'Краны шаровые для газа - ИТЦ Квазар';
  }, []);
    return(
      <div className="App">
        <Navigation />
          <div className="relative">
            {/* background image */}
            <div className="absolute inset-0 z-0"
              style={{
              backgroundImage: `url(${backgroundimg})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',}}
            ></div>

        {/* section starts here */}

          <section className="relative md:block hidden">
            <div className='w-full h-[200px] bg-gray-900/90 absolute'>
              <img className='w-full h-full object-cover mix-blend-overlay' src={indimg} alt="indimg" />
            </div>
            <div className='max-w-[1240px] mx-auto text-white relative'>
              <div className='px-4 py-12 md:block hidden'>
                <h3 className='text-5xl font-bold py-6 text-center'><Link to='/catalog'><span className="underline">Запорно-регулирующая арматура</span></Link></h3>
              </div>
                <div className='w-full h-[50px] relative'>
              </div>
            </div>
          </section>
         
          <section className="relative">
            <div className="container max-w-xl p-6 mx-auto space-y-12 lg:px-8 lg:max-w-7xl">
            <h2 className="text-3xl font-bold text-left sm:text-5xl">Краны шаровые для газа</h2>
              <div>
                <p className="mx-auto mt-4 text-xl text-left ">Шаровые краны являются важным элементом запорной арматуры и предназначены для регулировки и запирания потока газа в трубопроводах. 
                  Они обеспечивают надежное и быстрое открытие и закрытие потока, что делает их идеальными для использования в различных системах.
                    <br />
                    <br />
                    Тип совместимой рабочей среды для шаровых кранов определяется материалом, из которого они изготовлены. 
                    Большинство моделей шаровых кранов предназначены для работы с природными газами, сжиженными углеводородами и другими нейтральными газами.
                    <br />
                    <br />
                    В зависимости от назначения, шаровые краны могут быть выполнены из различных материалов, таких как сталь, нержавеющая сталь, бронза или пластик, 
                    что позволяет им эффективно работать в различных условиях, включая высокие давления и температуры. 
                    Правильный выбор материала и конструкции крана гарантирует его долговечность и надежность в эксплуатации.
                  </p>
              </div>
              <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
                <div>
                  <div className="mt-4 space-y-12">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center w-12 h-12 rounded-md">
                          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                            <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                          </svg> 
                        </div>
                      </div> 
                {/*
                        <h4 className="text-lg font-medium leadi ">Основные преимущества дисковых затворов заключаются в их простой конструкции и низкой стоимости.
                        Обычно затвор состоит из небольшого количества деталей, что облегчает процесс ремонта и замену вышедших из строя компонентов. В затворе всего четыре основных элемента: корпус, диск, поворотный вал (шпиндель) и седло.
                        </h4> */}
                    <p className="mt-2 ">Основные преимущества дисковых затворов заключаются в их простой конструкции и низкой стоимости.
                        Обычно затвор состоит из небольшого количества деталей, что облегчает процесс ремонта и замену вышедших из строя компонентов. В затворе всего четыре основных элемента: корпус, диск, поворотный вал (шпиндель) и седло.</p>
                  </div>
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 rounded-md">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                        </svg> 
                      </div>
                    </div>
                    <p className="mt-2">Поворотный вал (или шпиндель) дискового затвора играет ключевую роль в его работе и может быть выполнен как в цельном исполнении, так и в виде двух частей.</p>
                  </div>
                  <div className="flex">
                  <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 rounded-md">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                        </svg> 
                      </div>
                    </div>
                    <p className="mt-2">Корпус. Дисковый затвор обычно устанавливается между двумя фланцами, которые окружают его по окружности, что называется вафельной конструкцией. Альтернативный вариант включает специальные проушины для крепления шпилек обратных фланцев, что также обеспечивает надежное крепление затвора.
                      Кроме того, существует возможность приварки затворов встык, что может быть предпочтительным в некоторых условиях.</p>
                  </div>
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 rounded-md">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                        </svg> 
                      </div>
                    </div>
                    <p className="mt-2">Поворотный диск – элемент конструкции затвора, выполняющий функцию перекрытия потока рабочей среды. По своим функциям он аналогичен мембране в клапане или шару в шаровом кране.</p>
                  </div>
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 rounded-md">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                        </svg> 
                      </div>
                    </div>
                  <p className="mt-2">Уплотнение. Качество уплотнительного элемента играет решающую роль в работе дискового затвора. Наиболее распространены затворы со сменным уплотнением, представляющее собой кольцевую прокладку из высокопрочного пластика. Эти прокладки обеспечивают герметичное соединение и позволяют легко заменять изношенные элементы.
                    Существуют также затворы с уплотнением на поворотном диске, что может улучшить герметичность в открытом и закрытом положениях. Кроме того, некоторые конструкции имеют обрезиненный корпус, что также способствует повышению герметичности и устойчивости к воздействию агрессивных сред.</p>
                  </div>
                </div>
              </div>
              <div aria-hidden="true" className="mt-10 lg:mt-0">
                <img src={gazkran} alt="gazkran" className="mx-auto rounded-lg shadow-lg dark-bg-gray-500" />
              </div>
            </div>
            </div>
          </section>
          <section className="relative">
            <ContactForm />
          </section>
          <Footer />
      </div>
    </div>
  );
}
    

export default KranySharGaz;