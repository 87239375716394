import React from "react";


import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Catalog from "./Catalog";
import ZatvoryDiskovye from "./pages/ZatvoryDiskovye";
import ZatvoryDiskovyeSym from "./pages/ZatvoryDiskovyeSym";
import ZatvoryDiskovyeDv from "./pages/ZatvoryDiskovyeDv";
import ZatvoryDiskovyeTr from "./pages/ZatvoryDiskovyeTr";
import KranySharGaz from "./pages/KranySharGaz";
import KlapanyZapornye from "./pages/KlapanyZapornye";
import ZadvizhkiKlinovye from "./pages/ZadvizhkiKlinovye";
import Flancy from "./pages/Flancy";
import Kipia from "./pages/Kipia"; 
import Eldvig from "./pages/Eldvig";
import Kontakty from "./pages/Kontakty";


function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<About />} />
          <Route path="/about" element={<Home />} />
          <Route path="/catalog" element={<Catalog />} />
          <Route path="/kontaktnaya-informaciya" element={<Kontakty />} />
          <Route path="/catalog/zatvory-diskovye-povorotnye" element={<ZatvoryDiskovye />} />
          <Route path="/catalog/zatvory-diskovye-povorotnye/simmetrichnye" element={<ZatvoryDiskovyeSym />} />
          <Route path="/catalog/zatvory-diskovye-povorotnye/s-dvoinym-ekcentrisitetom" element={<ZatvoryDiskovyeDv />} />
          <Route path="/catalog/zatvory-diskovye-povorotnye/s-troinym-ekcentrisitetom" element={<ZatvoryDiskovyeTr />} />
          <Route path="/catalog/gazovye-krany-sharovye" element={<KranySharGaz />} />
          <Route path="/catalog/klapany-zapornye" element={<KlapanyZapornye />} />
          <Route path="/catalog/zadvizhki-klinovye" element={<ZadvizhkiKlinovye />} />
          <Route path="/catalog/flancy" element={<Flancy />} />
          <Route path="/catalog/kipia" element={<Kipia />} />
          <Route path="/catalog/eldvig" element={<Eldvig />} />
        </Routes>
      </Router> 
    </>
  );
}


export default App;
